<template>
	<a-modal v-model="visible" :title="false" width="60%" :footer="null" @cancel="_close" centered destroyOnClose>
		<a-descriptions title="个人档案信息" bordered>
			<a-descriptions-item label="姓名">
				{{ rowdata.name }}
			</a-descriptions-item>
			<a-descriptions-item label="性别">
				{{ rowdata.sex }}
			</a-descriptions-item>
			<a-descriptions-item label="出生日期">
				{{ rowdata.birthday }}
			</a-descriptions-item>
			<a-descriptions-item label="民族">
				{{ rowdata.nation }}
			</a-descriptions-item>
			<a-descriptions-item label="政治面貌">
				{{ rowdata.politicsStatus }}
			</a-descriptions-item>
			<a-descriptions-item label="照片">
				<img width="100px" height="150px" :src="rowdata.photo" alt="暂无" />
			</a-descriptions-item>
			<a-descriptions-item label="身份证号" :span="2">
				{{ rowdata.idCardNumber }}
			</a-descriptions-item>
			<a-descriptions-item label="婚姻状态">
				{{ rowdata.marriage }}
			</a-descriptions-item>
			<a-descriptions-item label="籍贯">
				{{ rowdata.orgion }}
			</a-descriptions-item>
			<a-descriptions-item label="户口所在地" :span="2">
				{{ rowdata.registerdAddress }}
			</a-descriptions-item>
			<a-descriptions-item label="毕业院校">
				{{ rowdata.schoolName }}
				贵州师范大学
			</a-descriptions-item>
			<a-descriptions-item label="学历">
				{{ rowdata.education }}
			</a-descriptions-item>
			<a-descriptions-item label="职称">
				{{ rowdata.rank }}
			</a-descriptions-item>
			<a-descriptions-item label="专业">
				{{ rowdata.specialty }}
			</a-descriptions-item>
			<a-descriptions-item label="参加工作时间">
				{{ rowdata.workDate }}
			</a-descriptions-item>
			<a-descriptions-item label="加入本单位时间">
				{{ rowdata.joinDate }}
			</a-descriptions-item>
			<a-descriptions-item label="员工类型">
				{{ rowdata.staffType }}
			</a-descriptions-item>
			<a-descriptions-item label="职务">
				{{ rowdata.jobName }}
			</a-descriptions-item>
			<a-descriptions-item label="电子邮件">
				{{ rowdata.email }}
			</a-descriptions-item>
			<a-descriptions-item label="手机号">
				{{ rowdata.phone }}
			</a-descriptions-item>
			<a-descriptions-item label="家庭住址" :span="2">
				{{ rowdata.homeAddress }}
			</a-descriptions-item>
			<a-descriptions-item label="合同开始日期">
				{{ rowdata.contractStart }}
			</a-descriptions-item>
			<a-descriptions-item label="合同截至日期">
				{{ rowdata.contractEnd }}
			</a-descriptions-item>
			<a-descriptions-item label="转正日期">
				{{ rowdata.officialDate }}
			</a-descriptions-item>
		</a-descriptions>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
export default {
	data() {
		return {
			visible: false,
			loading: false,
			time: '',
			id: '',
			rowdata: {},
			data: {
				title: '',
				content: ''
			}
		};
	},
	methods: {
		...apiUtil,
		show(row) {
			// utils.showSpin();
			console.log(row);
			this.rowdata = row;
			this.visible = true;
		},
		_close() {
			clearInterval(this.inter);
			this.visible = false;
		}
	}
};
</script>
<style>
.content img {
	max-width: 100% !important;
}
.countdown {
	width: 30px;
	height: 30px;
	border: 1px solid blue;
	border-radius: 50%;
	text-align: center;
}
.time {
	line-height: 30px;
}
</style>
